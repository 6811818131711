import React, { useEffect, useState } from "react";
import RootNavigator from "./navigation/RootNavigator";
import "./assets/app.css";
import "antd/dist/reset.css";
import ConfigProvider from "./context/ConfigProvider";
import { Alert, Modal } from "antd";
import { ThemeProvider } from "styled-components";
import { theme } from "./assets/theme";
import { AuthProvider } from "react-auth-kit";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./i18n";
import { MaintenanceMessage } from "./components/MaintenanceMessage";
import RemoteConfigProvider from "./firebase/RemoteConfigProvider";

const { ErrorBoundary } = Alert;
function App() {
  return (
    <ErrorBoundary>
      <ConfigProvider>
        <MaintenanceMessage>
          <RemoteConfigProvider defaults="hidden_features">
            <ThemeProvider theme={theme}>
              <AuthProvider
                authType="localstorage"
                authName={"_auth"}
                // cookieDomain={window.location.hostname}
                // cookieSecure={window.location.protocol === "https:"}
              >
                <RootNavigator />
              </AuthProvider>
            </ThemeProvider>
          </RemoteConfigProvider>
        </MaintenanceMessage>

        {/* <ThemeProvider theme={theme}>
            <AuthProvider
              authType="localstorage"
              authName={"_auth"}
              // cookieDomain={window.location.hostname}
              // cookieSecure={window.location.protocol === "https:"}
            >
              <RootNavigator />
            </AuthProvider>
          </ThemeProvider> */}
      </ConfigProvider>
    </ErrorBoundary>
  );
}

export default App;
