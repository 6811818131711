import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { activate, fetchAndActivate, getValue, remoteConfig } from ".";

type IContext = {
  config: any;
  readConfig: (key: string) => string[];
  checkHiddenFeature: (key: string) => {
    isHiddenFeature: boolean;
    hideNow: boolean;
  };
};

const RemoteConfigContext = createContext<IContext>({
  config: {},
  readConfig: () => [],
  checkHiddenFeature: () => ({
    isHiddenFeature: false,
    hideNow: false,
  }),
});

const RemoteConfigProvider = ({
  defaults,
  children,
}: {
  defaults: any;
  children: ReactNode;
}) => {
  const [config, setConfig] = useState<any>();

  useEffect(() => {
    const fetchRemoteConfig = async () => {
      await fetchAndActivate(remoteConfig);

      // Get the value for the key 'welcome_message' from Remote Config

      const configArray = defaults.split(",");

      const resConfig: any = {};

      for (const config of configArray) {
        const keyConfig = Object.keys(resConfig);

        if (!keyConfig.includes(config)) {
          const value = getValue(remoteConfig, config).asString();

          resConfig[`${config}`] = value || "";
        }
      }

      setConfig(resConfig);
    };

    fetchRemoteConfig();
    activate(remoteConfig);
  }, [defaults]);

  const readConfig = (key: string): string[] => {
    if (!key) return [];

    if (config && config[key]) {
      return config[key]?.split(",");
    }

    return [];
  };

  const isHideFeatureNow = (key: string): boolean => {
    return (
      process.env.REACT_APP_STAGE === 'production' &&
      readConfig('hidden_features').includes(key)
    );
  };

  const checkHiddenFeature = (
    key: string
  ): {
    isHiddenFeature: boolean;
    hideNow: boolean;
  } => {
    const isInclude = readConfig('hidden_features').includes(key);
    console.info('checkHiddenFeature', isInclude, readConfig('hidden_features'));
    if (isInclude && process.env.REACT_APP_STAGE === 'production') {
      return {
        isHiddenFeature: isInclude,
        hideNow: true,
      };
    } else if (isInclude) {
      return {
        isHiddenFeature: isInclude,
        hideNow: false,
        // hideNow: true,
      };
    } else {
      return {
        isHiddenFeature: false,
        hideNow: false,
        // hideNow: true,
      };
    }
  };

  return (
    <RemoteConfigContext.Provider
      value={{
        config,
        readConfig,
        checkHiddenFeature,
      }}
    >
      {children}
    </RemoteConfigContext.Provider>
  );
};

export const useRemoteConfig = () => {
  const context = useContext(RemoteConfigContext);
  return context;
};

export default RemoteConfigProvider;
