import React, { useState } from "react";
import {
  AppTable,
  getStandardError,
  HeaderSection,
  IAppTableColumn,
  useFetchList,
} from "@qlibs/react-components";
import { httpRequest } from "../../helpers/api";
import useProgramStore from '../../zustand/useProgramStore';
import { ProgramTalentProps } from '../../types/programTalent.type';
import { Button, message, Modal, Row, Spin } from 'antd';
import { UserProperties } from "../../services/openapi";
import { features } from "process";
import ListQuiz from "../quiz";
import { EQuizType } from "../../types/quiz.type";
import ListSurvey from "./components/ListSurvey";
import { DownloadOutlined } from '@ant-design/icons';

const DownloadReport = () => {
  const selectedProgram = useProgramStore((state) => state.selectedProgram);
  const [isLoadingGenerate, setIsLoadingGenerate] = useState<string>();
  const [isShowModal, setIsShowModal] = useState<boolean>(false)

  const programId = process.env.REACT_APP_INCLUDE_PROGRAM_ID === 'true' ? selectedProgram?.programId : undefined;

  const { isLoading, data: programTalents } = useFetchList<ProgramTalentProps>({
    httpRequest: httpRequest as any,
    endpoint: 'program-talents',
    initialQuery: {
      programId: selectedProgram?.programId,
    },
  });
  const handleCancelModalBack = () => {
    setIsShowModal(false)
  }
  const handleGenerateFinalReport = async (talentId: string, feature: string) => {
    setIsLoadingGenerate(talentId);

    // Set the endpoint URL based on the feature
    let method = 'POST';
    let endpoint: string = "";
    if (feature === 'Talent Individual Report') {
      endpoint = `/export-excel/individual-talent-report/${selectedProgram?.programId}`;
    } else if (feature === 'DBI Kick Off') {
      endpoint = `/export-excel/dbi-kick-off/${selectedProgram?.programId}`;
    } else if (feature === 'DBI Final Report') {
      endpoint = `/export-excel/dbi-final-report/${selectedProgram?.programId}`;
    } else if (feature === 'Progress Report') {
      method = 'GET';
      endpoint = `/final-report/download/${selectedProgram?.programId}`;
    }

    try {
      const response =
        method === 'POST'
          ? await httpRequest.post(endpoint, {}, { responseType: 'blob' })
          : await httpRequest.get(endpoint, { responseType: 'blob' });
      if (!response) {
        // throw new Error('Failed to download the report');
        message.error('Failed to download');
        return;
      }

      const disposition = response.headers['content-disposition'];
      let filename = 'downloaded_file';
      if (disposition && disposition.includes('filename=')) {
        filename = disposition.split('filename=')[1].replace(/"/g, '').trim();
      }
      console.log(filename)

      // Create a URL for the blob and download it with the extracted filename
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename; // Set the filename from the header
      document.body.appendChild(a);
      a.click();

      a.remove();
      window.URL.revokeObjectURL(url);
      message.success('Downloaded successfully');
    } catch(err) {
      getStandardError(err, {showToast: true});
    }
  };

  const columns: IAppTableColumn<any>[] = [
    {
      title: 'REPORT',
      dataIndex: 'feature',
      key: 'feature',
      keyId: 'featureId',
    },
    {
      title: 'ACTION',
      dataIndex: 'finalReport',
      key: 'finalReport',
      render: (_, record) => {
        return (
          <Row>
            {record.files.includes('raw') && (
              <Button
                size="small"
                type="primary"
                icon={<DownloadOutlined />}
                disabled={record.status === 'active' ? false : true}
                onClick={() =>
                  record.type === 'Download'
                    ? handleGenerateFinalReport(
                        record.featureId,
                        record.feature
                      )
                    : setIsShowModal(true)
                }
              >
                Download Excel (Raw)
              </Button>
            )}
          </Row>
        );
      }
    },
  ];

  // Example data with three features
  const data = [
    { featureId: '1', feature: 'Talent Individual Report', status: 'active', type: 'Download', files: ['raw'] },
    { featureId: '2', feature: 'DBI Kick Off', status: 'active', type: 'Download', files: ['raw'] },
    { featureId: '3', feature: 'DBI Final Report', status: 'active', type: 'Download', files: ['raw'] },
    { featureId: '4', feature: 'Feedback Evaluation Report', status: 'active', type: 'Modal', files: ['raw'] },
    { featureId: '5', feature: 'Progress Report', status: 'active', type: 'Download', files: ['raw'] },
  ];

  return (
    <React.Fragment>
      <HeaderSection title={"Download Report" + (programId ? '~' : '')} />

      <AppTable
        isLoading={isLoading}
        keyId="featureId"
        columns={columns}
        data={data}
        pagination={false}
      />
      <Modal
        title="Select Survey"
        open={isShowModal}
        onCancel={handleCancelModalBack}
        width={900}
        footer={false}
      >
        <ListSurvey quizType={[EQuizType.SURVEY]} />
      </Modal>
    </React.Fragment>
  );
};
export default DownloadReport;
