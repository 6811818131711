import { DownloadOutlined } from '@ant-design/icons';
import { getStandardError } from '@qlibs/react-components';
import { Button, message } from 'antd';
import { ButtonType } from 'antd/es/button';
import React, { useState } from 'react'
import { httpRequest } from '../../../helpers/api';

type Props = {
  programId: string;
  buttonType?: ButtonType;
}

export default function ButtonDownloadFinalReport(props: Props) {
  const [isLoadingDownloadFinalReport, setIsLoadingDownloadFinalReport] =
    useState(false);
    
  const downloadFinalReport = async () => {
    setIsLoadingDownloadFinalReport(true);
    try {
      const response = await httpRequest.get(
        `final-report/download/${props.programId}`,
        {
          responseType: 'blob',
        }
      );

      if (!response) {
        throw new Error('Failed to download the report');
      }

      const url = window.URL.createObjectURL(response.data);

      const a = document.createElement('a');
      a.href = url;
      a.download = `FinalReport.xlsx`; // FinalReport_{namaProgram}_{tanggal}
      document.body.appendChild(a);
      a.click();

      a.remove();
      window.URL.revokeObjectURL(url);
      setIsLoadingDownloadFinalReport(false);
      message.success('Downloaded successfully');
    } catch (error) {
      setIsLoadingDownloadFinalReport(false);
      console.error('Error downloading the report:', error);
      getStandardError(error, { showToast: true });
    }
  };
  
  return (
    <Button
      loading={isLoadingDownloadFinalReport}
      icon={<DownloadOutlined />}
      onClick={() => downloadFinalReport()}
      type={props.buttonType || "default"}
    >
      Download Final Report
    </Button>
  );
}