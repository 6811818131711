import React from "react";
import {
  HeaderSection,
  useFetchList,
} from "@qlibs/react-components";
import { httpRequest } from "../../helpers/api";
import ListTalentForEvaluation from './ListTalentForEvaluation';
import useProgramStore from '../../zustand/useProgramStore';
import { ProgramTalentProps } from '../../types/programTalent.type';
import { Button, Row, Spin } from 'antd';
import ButtonDownloadFinalReport from '../program/components/ButtonDownloadFinalReport';
import { FireOutlined } from '@ant-design/icons';
import usePermission from '../../hooks/usePermission';

const Evaluation = () => {
  const {isUserHasPermission} = usePermission();
  const selectedProgram = useProgramStore((state) => state.selectedProgram);

  const programId = process.env.REACT_APP_INCLUDE_PROGRAM_ID === 'true' ? selectedProgram?.programId : undefined;

  const { isLoading, data: programTalents } = useFetchList<ProgramTalentProps>({
    httpRequest: httpRequest as any,
    endpoint: 'program-talents',
    limit: 99999,
    initialQuery: {
      programId: selectedProgram?.programId,
    },
  });

  return (
    <React.Fragment>
      <HeaderSection
        title={'Evaluation ' + (programId ? '~' : '')}
        rightAction={
          <Row>
            {isUserHasPermission([
              'EVALUATION.SIDEBAR_MENU',
              'CHANGELOG.LIST',
            ]) && (
              <Button
                icon={<FireOutlined />}
                type="default"
                href="/evaluation-change-logs"
                style={{marginRight: 10}}
              >
                See Change Logs
              </Button>
            )}
            <ButtonDownloadFinalReport buttonType="primary" programId={programId || ''} />
          </Row>
        }
      />

      {isLoading ? (
        <Spin />
      ) : (
        <ListTalentForEvaluation
          talentIds={programTalents.map((pt) => pt.talentId)}
        />
      )}
    </React.Fragment>
  );
};
export default Evaluation;
