import React, { useEffect, useMemo, useRef, useState } from "react";
import { TagOutlined } from "@ant-design/icons";
import {
  BaseResponsePaginationProps,
  getStandardError,
  HeaderSection,
} from "@qlibs/react-components";
import { httpRequest } from "../../../helpers/api";
import { UserProperties } from '../../../services/openapi';
import ColumnDateTable, { generateDates, IDailyAchievementData, IEventData } from './ColumnDateTable';
import moment from 'moment';
import useProgramStore from '../../../zustand/useProgramStore';
import { SINGLE_BATCH_END_AT, SINGLE_BATCH_START_AT } from '../../../const/singleBatch';
import { EEventType, EventsProps } from '../../../types/event.type';
import { QuizAttemptProperties } from '../../../types/quiz.type';
import FilterDate from '../../../components/Form/FilterDate';
import { Button } from "antd";

type Props = { talentId?: string, hideHeader?: boolean }

const DailyAchievementByTalent = (props: Props) => {
  const selectedProgram = useProgramStore(state => state.selectedProgram)
  const [filterDate, setFilterDate] = useState({
    dateStartAt: moment(
      selectedProgram?.startAt || SINGLE_BATCH_START_AT
    ).format('YYYY-MM-DD'),
    dateEndAt: moment(selectedProgram?.endAt || SINGLE_BATCH_END_AT).format(
      'YYYY-MM-DD'
    ),
    startAt: selectedProgram?.startAt || SINGLE_BATCH_START_AT,
    endAt: selectedProgram?.endAt || SINGLE_BATCH_END_AT,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState<EventsProps[]>([]);
  const [talents, setTalents] = useState<UserProperties[]>([]);
  const [dailyAchievements, setDailyAchievements] = useState<QuizAttemptProperties[]>([]);
  
  // const [tableData, setTableData] = useState<IDailyAchievementData[]>([]);
  // const [eventData, setEventData] = useState<IEventData>({});

  useEffect(() => {
    fetchData();
  }, []);

  const dates = useMemo(
    () => generateDates(filterDate.dateStartAt, filterDate.dateEndAt),
    [filterDate]
  );

  const { tableData, eventData } = useMemo(() => {
    const data: IDailyAchievementData[] = [];
    let event: IEventData = {};

    event = events.reduce((acc, curr) => {
      if (
        ![
          EEventType.BASIC_TRAINING,
          EEventType.WSCC_TRAINING,
          EEventType.OJT,
          EEventType.DBI_PROJECT,
        ].includes(curr.eventType)
      ) {
        return acc;
      }

      if (curr.dates) {
        for (const dateItem of curr.dates) {
          if (dateItem) {
            const date = moment(dateItem.split(',')[0]).format('YYYY-MM-DD');

            if (!acc[date]) {
              acc[date] = [];
            }

            acc[date].push(curr);
          }
        }
      } else {
        const date = moment(curr.startAt).format('YYYY-MM-DD');

        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(curr);
      }

      return acc;
    }, {} as IEventData);

    for (const user of talents) {
      let dailyAchievement: IDailyAchievementData['dailyAchievement'] = {};

      dailyAchievement = dailyAchievements
        .filter((da) => da.userId === user.userId)
        .reduce((acc, curr) => {
          const date = moment(curr.startAt).format('YYYY-MM-DD');

          acc[date] = curr;

          return acc;
        }, {} as IDailyAchievementData['dailyAchievement']);

      for (const date of dates) {
        if (!dailyAchievement[date] && event[date]?.length > 0) {
          dailyAchievement[date] = { notSubmit: true } as any;
        }
      }
      
      const summary = {
        SUBMIT: calculateDailyAchievementStatus(dailyAchievement, 'submit'),
        'NOT SUBMIT': calculateDailyAchievementStatus(
          dailyAchievement,
          'not-submit'
        ),
      };
      data.push({
        key: user.userId,
        userId: user.userId,
        name: user.name,
        email: user.email,
        dailyAchievement,
        summary,
      });
    }

    return {tableData: data, eventData: event}
  }, [
    events,
    talents,
    dailyAchievements,
    dates,
  ]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let dailyAchievementQuizId;
      let configKey = 'DAILY_ACHIEVEMENT_QUIZ_ID';
      if (selectedProgram) {
        configKey = selectedProgram.programId + '__' + configKey;
      }
      const resConfig = await httpRequest.get('app-configs/' + configKey);
      if (resConfig?.data?.payload) {
        dailyAchievementQuizId = resConfig.data.payload.value;
      }

      const [resUser, resDailyAchievement, resEvent] = await Promise.all([
        httpRequest.get<BaseResponsePaginationProps<UserProperties>>(
          'users?roles=talent&sort=name:ASC' +
          (props.talentId ? '&userIds=' + props.talentId : '')
        ),
        httpRequest.get<BaseResponsePaginationProps<QuizAttemptProperties>>(
          `quiz-attempt?quizIds=${dailyAchievementQuizId}&finishedStartAt=${filterDate.startAt}&finishedEndAt=${filterDate.endAt}` +
          (props.talentId ? '&userIds=' + props.talentId : '')
        ),
        httpRequest.get<BaseResponsePaginationProps<EventsProps>>(
          `event?startAt=${filterDate.startAt}&endAt=${filterDate.endAt}`
        ),
      ]);

      if (resEvent?.data?.payload?.results) {
        setEvents(resEvent.data.payload.results);
      }
      if (resUser?.data?.payload?.results) {
        setTalents(resUser?.data?.payload?.results);
      }
      if (resDailyAchievement?.data?.payload?.results) {
        setDailyAchievements(resDailyAchievement?.data?.payload?.results);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      getStandardError(err, { showToast: true });
    }
  }

  const tableRef: any = useRef(null);

  const handleDownloadExcel = () => {
    if (tableRef.current) {
      tableRef.current.handleDownloadExcelTemplate();
    }
  };

  return (
    <React.Fragment>
      {!props.hideHeader && (
        <HeaderSection
          icon={<TagOutlined />}
          title="Daily Achievement by Talent"
          subtitle="Manage all daily achievements here"
          rightAction={
            <div style={{display: 'flex'}}>
              <FilterDate
                startAt={filterDate.startAt}
                endAt={filterDate.endAt}
                onChange={(value) => {
                  setFilterDate({
                    startAt: value.startAt,
                    endAt: value.endAt,
                    dateStartAt: moment(value.startAt).format('YYYY-MM-DD'),
                    dateEndAt: moment(value.endAt).format('YYYY-MM-DD'),
                  });
                }}
                {...(selectedProgram?.programId
                  ? {
                    enableStartAt: selectedProgram.startAt,
                    enableEndAt: selectedProgram.endAt,
                  }
                  : {})}
              />
              <Button type="primary" style={{ marginLeft: '15px' }} onClick={handleDownloadExcel}>
                Download Excel
              </Button>

            </div>
          }
        />
      )}

      <ColumnDateTable
        ref={tableRef}
        isLoading={isLoading}
        data={tableData}
        event={eventData}
        dateStartAt={filterDate.dateStartAt}
        dateEndAt={filterDate.dateEndAt}
      />
    </React.Fragment>
  );
};

export default DailyAchievementByTalent;

function calculateDailyAchievementStatus(objData: IDailyAchievementData['dailyAchievement'], status: 'submit' | 'not-submit') {
  return Object.keys(objData).filter(date => {
    if (status === 'submit') {
      return objData[date]?.quizId;
    } else {
      return !objData[date]?.quizId && objData[date]?.notSubmit;;
    }
  }).length;
}