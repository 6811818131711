import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Button, Modal, Typography, Form, Tag, Radio } from "antd";
import {
  AppTable,
  HeaderSection,
  IAppTableColumn,
  getErrorMessage,
  useFetchList,
  generateFormRules,
} from "@qlibs/react-components";
import RowFilter from "@qlibs/react-components/dist/Table/RowFilter";


import { httpRequest } from "../../../helpers/api";
import usePermission from "../../../hooks/usePermission";
import RenderQuizType, {
  renderTextQuizType,
} from "../../quiz/components/RenderQuizType";
import { EventsProps } from "../../../types/event.type";
import { EQuizType, ESubmitType, QuizProperties } from "../../../types/quiz.type";
import { getMenu } from "../../../const/menu";
import { getBasePath } from "../../quiz/helpers/menuPathGenerator";
import RenderSubmitType from "../../quiz/components/RenderSubmitType";

const FEATURE = "QUIZ";

type IAdditionalData = {
  [key: string]: { events?: EventsProps[] };
};
type Props = {
  quizType: EQuizType[];

};
const ListSurvey = (props: Props) => {
  const { label, description } = getMenu();
  const navigate = useNavigate();
  const { isUserHasPermission } = usePermission();


  // const { isLoadingAdditionalData, additionalData } =
  //   useAdditionalDataForList<IAdditionalData>({
  //     id: 'quizId',
  //     data,
  //     injects: [
  //       {
  //         injectedId: 'quizId',
  //         endpoint: '/event?externalIds=',
  //         endpointId: 'externalId',
  //         returnKey: 'events',
  //         multiple: true,
  //       },
  //     ],
  //   });

  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true)
  const [data, setData] = React.useState<any>([])
  const [formCopy] = Form.useForm();
  const newQuizTypeWatch = Form.useWatch("newQuizType", formCopy);

  const fetchQuiz = async () => {
    const quiz = await httpRequest.get<any>(`/quizzes?quizType=SURVEY&submitType=PER_EVENT&search=feedback`)
    setData(quiz.data.payload.results)
    setIsLoading(false)

  }

  useEffect(() => {
    fetchQuiz()
  }, [])

  const handleFeedbackEvaluationReport = async (quizid: string) => {

    const response = await httpRequest.post(`/export-excel/feedback-evaluation-report/${quizid}`, {}, { responseType: 'blob' })
    if (!response) {
      throw new Error('Failed to download the report');
    }

    const disposition = response.headers['content-disposition'];
    let filename = 'downloaded_file';
    if (disposition && disposition.includes('filename=')) {
      filename = disposition.split('filename=')[1].replace(/"/g, '').trim();
    }
    console.log(filename)


    // Create a URL for the blob and download it with the extracted filename
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // Set the filename from the header
    document.body.appendChild(a);
    a.click();


    a.remove();
    window.URL.revokeObjectURL(url);
    message.success('Downloaded successfully');

  };

  const columns: IAppTableColumn<QuizProperties>[] = [
    {
      title: "TITLE",
      dataIndex: "title",
      key: "quizTitle",
      // type: "detail",
      keyId: "quizId",
      showOriginalValue: true,
    },
    props.quizType.length > 1
      ? {
        title: "QUIZ TYPE",
        dataIndex: "quizType",
        key: "quizType",
        render: (v: EQuizType) => <>{<RenderQuizType type={v} />}</>,
      }
      : {},
    props.quizType.length > 0 && props.quizType.includes(EQuizType.SURVEY)
      ? {
        title: "SUBMIT TYPE",
        dataIndex: "submitType",
        key: "submitType",
        render: (v: ESubmitType) => <>{<RenderSubmitType type={v} />}</>,
      }
      : {},
    {
      title: "TOTAL SUBMISSION",
      dataIndex: "quizAttemptCount",
      key: "quizAttemptCount",
      render: (v: string) => <>{v ? v : 0}</>,
    },
    {
      title: "LATEST SUBMIT",
      dataIndex: ["latestAttempt", "finishedAt"],
      key: "finishedAt",
      type: "datetime",
    },
    {
      title: 'ACTION',
      dataIndex: 'finalReport',
      key: 'finalReport',
      render: (_, record) => {
        const isDisabled = Number(record.quizAttemptCount) === 0; // Cek apakah total submission adalah 0
        return (
          <Button
            size="small"
            type="primary"
            onClick={() => handleFeedbackEvaluationReport(record.quizId)}
            disabled={isDisabled} // Nonaktifkan tombol jika isDisabled true
          >
            Download
          </Button>
        );
      },
    }
  ];


  const handleMoveToSubmissions = async (id: string) => {
    const findQuiz = data.find((v: any) => v.quizId === id);

    navigate(`/${getBasePath()}/${findQuiz?.quizId}/result`);
  };

  const rightAction: any = isUserHasPermission([FEATURE + ".CREATE"]) && (
    <Button
      icon={<PlusOutlined />}
      style={{ marginRight: 20 }}
      type="primary"
      onClick={() => {
        navigate({ pathname: "add" }, { relative: "route" });
      }}
    >
      Add {props.quizType.map((item) => renderTextQuizType(item)).join(" & ")}
    </Button>
  );

  return (
    <React.Fragment>

      <AppTable
        isLoading={isLoading || isLoadingAction}
        keyId="quizId"
        columns={columns.filter((v) => v.key)}
        data={data}
        pagination={false}
      />
    </React.Fragment>
  );
};

export default ListSurvey;
