import React from "react";
import { Badge, Menu } from "antd";
import type { MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import usePermission from "../hooks/usePermission";
import { MENUS } from '../const/menu';
import { useRemoteConfig } from '../firebase/RemoteConfigProvider';
import DotHiddenFeature from '../firebase/HiddenFeature';

const Sidebar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isUserHasPermission } = usePermission();
  const {checkHiddenFeature} = useRemoteConfig();

  const filterMenuOnlyHasPermission = (items: any[]) => {
    return items
      .map((item) => {
        if (item.permission && Array.isArray(item.permission)) {
          item.permission = isUserHasPermission(item.permission, item.permissionOp);
        }

        if (item.children) {
          item.children = filterMenuOnlyHasPermission(item.children);

          if (item.children.length === 0) {
            item.permission = false;
          } else {
            item.permission = true;
          }
        }

        if (item.permission) {
          return item;
        } else {
          return null;
        }
      })
      .filter((item) => item);
  };

  const filterHiddenFeatures = (items: any[]) => {
    const newItems = [...items];
    return newItems
      .map((item) => {
        if (item.configKey) {
          const check = checkHiddenFeature(item.configKey);
          console.info('filterHiddenFeatures check', check);
          if (check.isHiddenFeature && check.hideNow) {
            item.label = null;
          } else if (check.isHiddenFeature && !check.hideNow) {
            item.label = (
              <DotHiddenFeature configKey={item.configKey} checkResult={check}>
                {item.configLabel || item.label}
              </DotHiddenFeature>
            );
          } else {
            //
          }

          // item.configKey = null;
        }

        if (item.children) {
          item.children = filterHiddenFeatures(item.children);

          if (item.children.length === 0) {
            item.label = null;
          } else {
            // item.label = true;
          }
        }

        if (item.label) {
          return item;
        } else {
          return null;
        }
      })
      .filter((item) => item && item.label);
  }

  let items = filterMenuOnlyHasPermission(MENUS) as MenuProps["items"];
  items = filterHiddenFeatures(items || []);
  console.info('after hidden features', items);

  const convertPathName = () => {
    const pathname = location.pathname.split("/");
    const res = "/" + pathname[1];
    return res;
  };

  const getSelectedParent = () => {
    const currentMenu = convertPathName();
    var parent: string[] = [];
    if (items) {
      for (const group of items) {
        if (group && "children" in group && group.children) {
          for (const submenu of group.children) {
            var current = String(submenu?.key ? submenu.key : "");
            if (submenu && "children" in submenu && submenu.children) {
              for (const item of submenu.children) {
                if (
                  item &&
                  "path" in item &&
                  (item as any).path === currentMenu &&
                  current !== undefined
                ) {
                  parent.push(current);
                }
              }
            }
          }
        }
      }
    }
    return parent;
  };

  return (
    <Menu
      mode="inline"
      style={{ paddingBottom: 40 }}
      defaultSelectedKeys={[convertPathName()]}
      defaultOpenKeys={getSelectedParent()}
      items={items}
      onClick={({ key }) => {
        navigate(key);
      }}
    />
  );
};
export default Sidebar;
