import { initializeApp } from "firebase/app";
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from "firebase/remote-config";
import { useState, useEffect } from "react";
import { Alert, Col, Modal, Typography } from "antd";
import { remoteConfig } from "../firebase";

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// };

// let app;
// let remoteConfig: any;

// if (firebaseConfig.apiKey) {
//     app = initializeApp(firebaseConfig);
//     remoteConfig = getRemoteConfig(app);
// }

type Props = {
  children?: React.ReactNode;
};

export const MaintenanceMessage: React.FC<Props> = ({ children }) => {
  const [message, setMessage] = useState();
  const [blocking, setBlocking] = useState(false);
  const [alert, setAlert] = useState<boolean>();
  const [messageUpdate, setMessageUpdate] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>();

  const compareVersions = (v1: string, v2: string) => {
    const [a, b] = [v1, v2].map((v) => v.split(".").map(Number));
    while (a.length < b.length) a.push(0);
    while (b.length < a.length) b.push(0);
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return a[i] > b[i] ? 1 : -1;
    }
    return 0;
  };
  const getRemoteConfig = async () => {
    try {
      const message: any = await fetchData();
      const result = JSON.parse(message);
      const version = result.cms;
      setMessageUpdate(result.cms_message);
      const cmsVersion = process.env.REACT_APP_VERSION_NAME ?? "1.0.0";
      const data = compareVersions(cmsVersion, version);
      if (data < 0 && result.cms_force_update) {
        setIsVisible(true);
      }
      if (data < 0 && !result.cms_force_update) {
        setAlert(true);
      }
    } catch (error) {
      console.error("Error fetching remote config:", error);
    }
  };

  useEffect(() => {
    if (remoteConfig) {
      fetchAndActivate(remoteConfig)
        .then(() => {
          const configData = getValue(remoteConfig, "maintenance_message");
          const jsonParse = JSON.parse(configData.asString());
          console.log(JSON.stringify(jsonParse));
          setMessage(jsonParse.message);
          setBlocking(jsonParse.blocking);
        })
        .catch((err) => {
          console.log("Failed to fetch maintenance_message", err);
        });
      getRemoteConfig();
    }
  }, []);

  return (
    <>
      {alert ? (
        <Alert message={messageUpdate} type="warning" showIcon closable />
      ) : null}

      {/* <Modal
                title='Update Version'
                open={isVisible}
                footer={null}
                closable={false}
                maskClosable={false}
            >
                <div>
                    <p>{messageUpdate}</p>
                </div>
            </Modal> */}
      {message && (
        <>
          {!blocking && (
            <Alert
              // style={{ position: 'fixed', top: 0, width: '100%', zIndex: 100, paddingTop: 10, color: 'red' }}
              message={message}
              type="warning"
              showIcon
              closable
            />
          )}

          <Modal
            title="Maintenance"
            open={blocking}
            footer={null}
            maskClosable={false}
            closable={false}
          >
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography.Text
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                {message}
              </Typography.Text>
            </Col>
          </Modal>
        </>
      )}

      {children}
    </>
  );
};

const fetchData = async () => {
  try {
    await fetchAndActivate(remoteConfig);
    const message = getValue(remoteConfig, "latest_version").asString();
    console.log("Remote Config Message:", message);
    return message;
  } catch (err) {
    console.error("Error fetching remote config:", err);
    throw err; // Throw the error to handle it in the caller
  }
};

export { remoteConfig, fetchAndActivate, getValue, fetchData };
